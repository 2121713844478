/* Main container for categories */
.category-mainbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Grid container for the categories */
.categories-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Five equal columns */
  margin: 50px auto;
  width: 100%;
  max-width: 1200px;
  height: 500px;
  gap: 20px;
  padding: 0 20px;
}

/* Individual category box styling */
.category-box {
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  padding: 30px;
}

/* Text inside each category box */
.category-text {
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

/* Hover effect for the category text */
.category-box:hover .category-text {
  color: var(--gold);
}

/* Mobile responsiveness */
@media (max-width: 900px) {
  .categories-container {
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
    grid-template-rows: auto auto; /* Two rows for the first 4 items */
    gap: 10px;
  }
  .category-text {
    font-size: 22px !important;
  }
  .category-box {
    width: 180px;
    margin: 20px 5px;

    height: 100px;
  }

  /* Make the fifth item span the entire width on smaller screens */
  .category-box:nth-child(5) {
    width: 80%;
    grid-column: 1 / -1; /* Span the entire row for the last item */
  }
}

/* Smaller screen adjustments (mobile-first design) */
@media (max-width: 600px) {
  .categories-container {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Single column on very small screens */
    grid-template-rows: auto; /* Each item takes a row */
    gap: 0px;
  }

  .category-box {
    width: 150px;
    height: 100px;
  }
}
