* {
  --dark-bg: #000000;
  --gold: #d4af37;

  --main-font: "Euclid Circular A";
  --skicom-font: "ExoSoft-Italic";
}

/* Local Font Import */
@font-face {
  font-family: "Euclid Circular A";
  src: url("./Euclid Circular A.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ExoSoft-Italic";
  src: url("./ExoSoft-Italic.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

body {
  max-width: 101%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  font-family: "Euclid Circular A";
  background-image: linear-gradient(
      200deg,
      rgba(171, 171, 171, 0.05) 0%,
      rgba(171, 171, 171, 0.05) 23%,
      rgba(90, 90, 90, 0.05) 23%,
      rgba(90, 90, 90, 0.05) 48%,
      rgba(65, 65, 65, 0.05) 48%,
      rgba(65, 65, 65, 0.05) 61%,
      rgba(232, 232, 232, 0.05) 61%,
      rgba(232, 232, 232, 0.05) 100%
    ),
    linear-gradient(
      126deg,
      rgba(194, 194, 194, 0.05) 0%,
      rgba(194, 194, 194, 0.05) 11%,
      rgba(127, 127, 127, 0.05) 11%,
      rgba(127, 127, 127, 0.05) 33%,
      rgba(117, 117, 117, 0.05) 33%,
      rgba(117, 117, 117, 0.05) 99%,
      rgba(248, 248, 248, 0.05) 99%,
      rgba(248, 248, 248, 0.05) 100%
    ),
    linear-gradient(
      144deg,
      rgba(64, 64, 64, 0.05) 0%,
      rgba(64, 64, 64, 0.05) 33%,
      rgba(211, 211, 211, 0.05) 33%,
      rgba(211, 211, 211, 0.05) 50%,
      rgba(53, 53, 53, 0.05) 50%,
      rgba(53, 53, 53, 0.05) 75%,
      rgba(144, 144, 144, 0.05) 75%,
      rgba(144, 144, 144, 0.05) 100%
    ),
    linear-gradient(329deg, hsl(148, 0%, 0%), hsl(148, 0%, 0%));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, var(--gold)),
    color-stop(0.72, var(--dark-bg))
  );
}

a {
  text-decoration: none;
  color: inherit;
}
