.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  background-color: #000;
}

.gif-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-gif {
  width: 100%; /* Adjust the size based on your GIF */
  height: auto;
  animation: pulse 2s infinite; /* Optional pulsing effect */
}

.bottom-loader {
  position: absolute;
  bottom: 20px; /* Adjust the position as needed */
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 9999;
}

.loading-text {
  margin-top: 10px;
  font-size: 18px;
  color: #fff;
}

/* Optional pulsing animation for the GIF */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
