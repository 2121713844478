.carousel-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.carousel-slide {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}

.carousel-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  padding: 20px;
}

.carousel-text {
  flex: 1;
  color: white;
  max-width: 50%; /* Text takes up half of the slide */
  text-align: right;
}

.carousel-text h2 {
  font-size: 56px;
  margin-bottom: 20px;
}
.carousel-text p {
  font-size: 26px;
  margin-bottom: 10px;
}

.carousel-text .home-btn {
  color: #fff;
  background-color: #000;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.carousel-text .home-btn:hover {
  background-color: #333;
}

.carousel-image {
  flex: 1;
  max-width: 70%; /* Image takes up the other half */
  height: auto;
  object-fit: cover;
  padding-right: 25px;
  transition: transform 0.5s ease-in-out; /* Smooth transition for scaling */
}

.carousel-image:hover {
  transform: scale(1.1); /* Scale image on hover */
}

.slick-dots li button:before {
  color: white; /* Change dot color */
  padding: 10px;
}

.slick-prev,
.slick-next {
  z-index: 1;
  color: var(--blue);
  background-color: var(--blue); /* Style arrows */
  border-radius: 50%;
  padding: 20px;
}

.slick-prev:before,
.slick-next:before {
  color: var(--blue); /* Ensure arrows are visible */
}

.home-btn {
  display: inline-block;
  padding: 10px 25px;
  background-color: #fd8a58; /* Background color */
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  transition: color 0.4s ease;
  z-index: 1;
}

.home-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--gold);
  color: white; /* Hover animation color */
  z-index: -1;
  transition: transform 0.4s ease;
  transform: scaleX(0);
  transform-origin: left;
}

.home-btn:hover::before {
  transform: scaleX(1); /* Expands from left to right on hover */
  transform-origin: right;
}

.home-btn:hover {
  color: #fff; /* Change text color on hover */
}

.cycle-colors {
  animation: cycleColors 3s infinite; /* 3s for each cycle, adjust as needed */
  display: inline-block; /* Ensure inline behavior with span */
}

/* Define the color keyframes for cycling */
@keyframes cycleColors {
  0% {
    color: #fd8a58; /* Starting color */
  }
  33% {
    color: #6eecf4; /* Second color */
  }
  66% {
    color: #507378; /* Third color */
  }
  100% {
    color: var(--gold); /* Back to the first color */
  }
}

/* Mobile Responsive Styling */
@media (max-width: 768px) {
  .carousel-slide {
    height: 600px;
    background: none;
  }
  .carousel-content {
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .carousel-text {
    text-align: center;
    max-width: 100%; /* Take full width on mobile */
    margin-bottom: 20px; /* Add spacing between text and image */
  }

  .carousel-image {
    max-width: 100%; /* Image takes full width on mobile */
    margin: 0 0 20px 0; /* Add spacing between image and text */
  }

  .carousel-text h2 {
    font-size: 28px; /* Smaller font size for mobile */
  }

  .carousel-text .home-btn {
    padding: 10px 15px;
    font-size: 14px;
  }
}
