.home-container {
  min-height: 100vh;
  background-image: linear-gradient(
      200deg,
      rgba(171, 171, 171, 0.05) 0%,
      rgba(171, 171, 171, 0.05) 23%,
      rgba(90, 90, 90, 0.05) 23%,
      rgba(90, 90, 90, 0.05) 48%,
      rgba(65, 65, 65, 0.05) 48%,
      rgba(65, 65, 65, 0.05) 61%,
      rgba(232, 232, 232, 0.05) 61%,
      rgba(232, 232, 232, 0.05) 100%
    ),
    linear-gradient(
      126deg,
      rgba(194, 194, 194, 0.05) 0%,
      rgba(194, 194, 194, 0.05) 11%,
      rgba(127, 127, 127, 0.05) 11%,
      rgba(127, 127, 127, 0.05) 33%,
      rgba(117, 117, 117, 0.05) 33%,
      rgba(117, 117, 117, 0.05) 99%,
      rgba(248, 248, 248, 0.05) 99%,
      rgba(248, 248, 248, 0.05) 100%
    ),
    linear-gradient(
      144deg,
      rgba(64, 64, 64, 0.05) 0%,
      rgba(64, 64, 64, 0.05) 33%,
      rgba(211, 211, 211, 0.05) 33%,
      rgba(211, 211, 211, 0.05) 50%,
      rgba(53, 53, 53, 0.05) 50%,
      rgba(53, 53, 53, 0.05) 75%,
      rgba(144, 144, 144, 0.05) 75%,
      rgba(144, 144, 144, 0.05) 100%
    ),
    linear-gradient(329deg, hsl(148, 0%, 0%), hsl(148, 0%, 0%));
}

/* Hero Section */
.hero-section {
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 0 50px;

  color: white;
}

.hero-title {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-subtitle {
  font-size: 1.5rem;
  color: #ddd;
}

/* Image Section */
.categories-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
}

.animated-image {
  width: 80%;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease-in-out;
}

.animated-image:hover {
  transform: scale(1.05);
}

/* Text Section */
.latest-products-section {
  text-align: center;
  padding: 100px 20px;
  background-image: linear-gradient(
      112.5deg,
      rgb(214, 214, 214) 0%,
      rgb(214, 214, 214) 10%,
      rgb(195, 195, 195) 10%,
      rgb(195, 195, 195) 53%,
      rgb(176, 176, 176) 53%,
      rgb(176, 176, 176) 55%,
      rgb(157, 157, 157) 55%,
      rgb(157, 157, 157) 60%,
      rgb(137, 137, 137) 60%,
      rgb(137, 137, 137) 88%,
      rgb(118, 118, 118) 88%,
      rgb(118, 118, 118) 91%,
      rgb(99, 99, 99) 91%,
      rgb(99, 99, 99) 100%
    ),
    linear-gradient(
      157.5deg,
      rgb(214, 214, 214) 0%,
      rgb(214, 214, 214) 10%,
      rgb(195, 195, 195) 10%,
      rgb(195, 195, 195) 53%,
      rgb(176, 176, 176) 53%,
      rgb(176, 176, 176) 55%,
      rgb(157, 157, 157) 55%,
      rgb(157, 157, 157) 60%,
      rgb(137, 137, 137) 60%,
      rgb(137, 137, 137) 88%,
      rgb(118, 118, 118) 88%,
      rgb(118, 118, 118) 91%,
      rgb(99, 99, 99) 91%,
      rgb(99, 99, 99) 100%
    ),
    linear-gradient(
      135deg,
      rgb(214, 214, 214) 0%,
      rgb(214, 214, 214) 10%,
      rgb(195, 195, 195) 10%,
      rgb(195, 195, 195) 53%,
      rgb(176, 176, 176) 53%,
      rgb(176, 176, 176) 55%,
      rgb(157, 157, 157) 55%,
      rgb(157, 157, 157) 60%,
      rgb(137, 137, 137) 60%,
      rgb(137, 137, 137) 88%,
      rgb(118, 118, 118) 88%,
      rgb(118, 118, 118) 91%,
      rgb(99, 99, 99) 91%,
      rgb(99, 99, 99) 100%
    ),
    linear-gradient(90deg, rgb(195, 195, 195), rgb(228, 228, 228));
  background-blend-mode: overlay, overlay, overlay, normal;
}

.latest-products-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.latest-products-section p {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
}

.hero-section,
.categories-section,
.latest-products-section {
  will-change: opacity, transform;
}
