/* General styles for AppBar and Toolbar */
.appbar {
  background-image: radial-gradient(
    circle at top right,
    rgb(78, 78, 78) 0%,
    rgb(78, 78, 78) 1%,
    rgb(72, 72, 72) 1%,
    rgb(72, 72, 72) 10%,
    rgb(65, 65, 65) 10%,
    rgb(65, 65, 65) 22%,
    rgb(59, 59, 59) 22%,
    rgb(59, 59, 59) 23%,
    rgb(53, 53, 53) 23%,
    rgb(53, 53, 53) 28%,
    rgb(46, 46, 46) 28%,
    rgb(46, 46, 46) 37%,
    rgb(40, 40, 40) 37%,
    rgb(40, 40, 40) 100%
  ) !important;
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it's always on top */
}

.blue {
  transition: 0.3s ease-in-out;
}

.blue:hover {
  color: var(--gold);
}

.web-title {
  font-family: var(--main-font);
  font-size: clamp(10px, 1vw, 28px);

  color: var(--gold);
  text-align: center;
  display: flex;
  justify-content: left;
  padding-right: 50px;
}
/* Desktop-specific styles */

/* Mobile menu styles */
.mobile-menu {
  display: flex;
  align-items: center;
}

/* Show desktop menu on wider screens */
@media (min-width: 900px) {
  .desktop-menu,
  .desktop-search {
    display: flex;
  }

  .mobile-menu {
    display: none;
  }
}

/* Additional padding or margin adjustments for mobile if needed */
@media (max-width: 900px) {
  .mobile-menu {
    padding: 10px;
  }
}
