#products_heading {
  margin-top: 1.8rem;
}

.card {
  height: 60vh;
  width: 15vw;
  transition: all 0.5s ease-in-out !important;
}

.card:hover {
  transform: scale(1.2);
}
.products-latest {
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 100vw;
}
.product-link {
  color: #2e2e2e;
  font-size: clamp(0.5vw, 1vw, 1.5vw);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-decoration: none;
}

.product-link:hover {
  color: var(--blue);
  text-decoration: none;
}

.card-body {
  padding-left: 0;
}

.card-text {
  font-size: 1.4rem;
}

.card-img-top {
  width: 200px;
  height: 150px;
  object-fit: contain;
}

.ratings {
  margin: 10px 0;
}

#no_of_reviews {
  font-size: 0.9rem;
  color: grey;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.products-price {
  margin-bottom: 10px;
}

#view_btn {
  background-color: var(--gold);
  padding: 0.4rem 1.2rem;

  text-decoration: none;
  color: white;
}

/* Pagination */
.page-item.active .page-link {
  background-color: var(--gold);
  border-color: var(--gold);
  border-radius: 2px;
  padding: 10px;
  color: white;
}

.page-link {
  color: var(--gold);
  background-color: rgb(150, 151, 170);
  border-radius: 4px;
  padding: 10px;
}

.page-link:hover {
  color: white;
}

@media (max-width: 768px) {
  .products-latest {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */

    gap: 10px;
  }
  .card {
    height: 50vh;
    width: 40vw;
    transition: all 0.5s ease-in-out !important;
  }
  .product-link {
    color: #2e2e2e;
    font-size: clamp(3.5vw, 1vw, 4.5vw);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-decoration: none;
  }
  #view_btn {
    padding: 5px 10px;
  }
}
